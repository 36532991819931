import React from 'react';
import FormLabel from './FormLabel';

import './Textarea.scss';

export default ({ label, id, ...rest }) => {
    return (
        <div className="textarea">
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <textarea className="textarea__input" id={id} {...rest}></textarea>
        </div>
    );
};
