import React from 'react';

import Collapse from './Collapse';

import Social from '../Social/Social';
import Button from '../Button/Button';
import Form, { FormGroup, Textarea, Input } from '../Form';
import SR from '../SR/SR';

import './Contact.scss';

export default ({ heading }) => {
    return (
        <div className="contact">
            {heading && <h2 className="contact__heading h1">{heading}</h2>}
            <div className="contact__section contact__section--collapse">
                <Collapse heading="Send a message">
                    <Form
                        method="post"
                        action="/thank-you/"
                        name="contact"
                        data-netlify-honeypot="contact_hp"
                        data-netlify="true"
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <SR>
                            <label>
                                Don’t fill this out if you're human: <input name="contact_hp" />
                            </label>
                        </SR>
                        <FormGroup>
                            <Input type="text" name="name" id="name" label="Name" required="required" />
                        </FormGroup>
                        <div className="form__row">
                            <FormGroup>
                                <Input type="tel" name="phone" id="phone" label="Mobile" required="required" />
                            </FormGroup>
                            <FormGroup>
                                <Input type="email" name="email" id="email" label="Email" required="required" />
                            </FormGroup>
                        </div>
                        <FormGroup>
                            <Textarea label="Your message" name="message" id="message" required="required"></Textarea>
                        </FormGroup>
                        <div className="form__group form__group--footer">
                            <Button type="submit">Submit</Button>
                        </div>
                    </Form>
                </Collapse>
            </div>
            <div className="contact__section">
                <Social />
                <a href="mailto:wayneem@blackdotconsulting.co.uk">hello@blackdotconsulting.co.uk</a>
            </div>
        </div>
    );
};
