import React, { useState } from 'react';
import classNames from 'classnames';
import './Collapse.scss';

export default ({ heading, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div
            className={classNames({
                collapse: true,
                'collapse--open': isOpen,
            })}
        >
            <button className="collapse__trigger" onClick={() => setIsOpen(!isOpen)}>
                {heading}
                {isOpen ? (
                    <svg className="collapse__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 66">
                        <path
                            d="M120.84.12l3.54 3.54-62.22 62.22-.16-.16-.16.16L-.38 3.66 3.16.12 62 58.96 120.84.12z"
                            fill="#000"
                            fill-rule="evenodd"
                        />
                    </svg>
                ) : (
                    <svg className="collapse__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 125">
                        <path d="M65 0v60h60v5H65v60h-5V65H0v-5h60V0h5z" fill="#000" />
                    </svg>
                )}
            </button>
            <div className="collapse__body">{children}</div>
        </div>
    );
};
