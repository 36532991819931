import React from 'react';

import './Button.scss';

export default ({ children, ...rest }) => {
    return (
        <button className="button" {...rest}>
            {children}
        </button>
    );
};
