import React from 'react';

import './Form.scss';

export default ({ children, ...rest }) => {
    return (
        <form className="form" {...rest}>
            {children}
        </form>
    );
};
