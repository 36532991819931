import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Band from '../components/Band/Band';
import Typography from '../components/Typography';
import Contact from '../components/Contact/Contact';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO';

export default ({ data }) => (
    <Layout>
        <SEO title="Privacy Policy" description="" />
        <Hero
            title="Black Dot recruitment privacy policy"
            mobileImage={data.mobileImage}
            desktopImage={data.desktopImage}
            logoColor="white"
        />
        <Band size="md">
            <Container>
                <Typography>
                    <PageHeader>Welcome to the Black Dot privacy notice</PageHeader>

                    <p>
                        Black Dot Recruitment Limited respects your privacy and is committed to protecting your personal
                        data. This privacy notice will inform you as to how we look after your personal data when you
                        visit our website (regardless of where you visit it from) and tell you about your privacy rights
                        and how the law protects you.
                    </p>

                    <p>
                        This privacy notice is provided in a layered format so you can click through to the specific
                        areas set out below. Please also use the Glossary to understand the meaning of some of the terms
                        used in this privacy notice.
                    </p>

                    <ol>
                        <li>Important information and who we are</li>
                        <li>The data we collect about you</li>
                        <li>How is your personal data collected</li>
                        <li>How we use your personal data</li>
                        <li>Disclosures of your personal data</li>
                        <li>International transfers</li>
                        <li>Data security</li>
                        <li>Data retention</li>
                        <li>Your legal rights</li>
                    </ol>

                    <h2>1. Important information and who we are</h2>

                    <h3>Purpose of the this privacy notice</h3>

                    <p>
                        This privacy notice aims to give you information on how Black Dot Recruitment Limited collects
                        and processes your personal data through your use of this website, including any data you may
                        provide through this website when you complete our enquiry form.
                    </p>

                    <p>
                        It is important that you read this privacy notice together with any other privacy notice we may
                        provide. In order that on specific occasions when we are collecting or processing your personal
                        data you are fully aware of how and why we are using your data. This privacy notice supplements
                        the other notices and is not intended to override them.
                    </p>

                    <h3>Controller</h3>

                    <p>
                        Black Dot Recruitment Limited is the controller and responsible for your personal data
                        (collectively referred to as “COMPANY”, “we”, “us” or “our” in this privacy notice).
                    </p>

                    <h3>Contact details</h3>

                    <p>
                        Our full details are: Black Dot Recruitment Limited, a company registered in England and Wales
                        with company number 9671817, registered office: 4 Claridge Court, Lower Kings Road, Berkhamsted,
                        Hertfordshire HP4 2AF.
                    </p>

                    <p>
                        You have the right to make a complaint at any time to the Information Commissioner’s Office
                        (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would,
                        however, appreciate the chance to deal with your concerns before you approach the ICO so please
                        contact us in the first instance.
                    </p>

                    <p>
                        It is important that the personal data we hold about you is accurate and current. Please keep us
                        informed if your personal data changes during your relationship with us.
                    </p>

                    <p>This version was last updated on 9th July 2020.</p>

                    <h3>Third party links</h3>

                    <p>
                        This website may include links to third-party websites, plug-ins and applications. Clicking on
                        those links or enabling those connections may allow third parties to collect or share data about
                        you. We do not control these third-party websites and are not responsible for their privacy
                        statements. When you leave our website, we encourage you to read the privacy notice of every
                        website you visit.
                    </p>

                    <h2>2. The data we collect about you</h2>

                    <p>
                        Personal data, or personal information, means any information about an individual from which
                        that person can be identified. It does not include data where the identity has been removed
                        (anonymous data).
                    </p>

                    <p>
                        We may collect, use, store and transfer different kinds of personal data about you which we have
                        grouped together as follows:
                    </p>

                    <ul>
                        <li>
                            <strong>Identity Data</strong> includes first name and last name,
                        </li>
                        <li>
                            <strong>Contact Data</strong> includes email address and telephone numbers.
                        </li>
                        <li>
                            <strong>CV’s and Portfolios</strong> includes documents and web links
                        </li>
                        <li>
                            <strong>References from previous employers</strong>
                        </li>
                        <li>
                            <strong>The right to work in specific countries</strong>
                        </li>
                        <li>
                            <strong>Technical Data</strong> includes internet protocol (IP) address, your login data,
                            browser type and version, time zone setting and location, browser plug-in types and
                            versions, operating system and platform and other technology on the devices you use to
                            access this website.
                        </li>
                        <li>
                            <strong>Usage Data</strong> includes information about how you use our website and services.
                        </li>
                        <li>
                            <strong>Marketing and Communications Data</strong> includes your preferences in receiving
                            marketing from us and our third parties and your communication preferences.
                        </li>
                    </ul>

                    <p>
                        We also collect, use and share Aggregated Data such as statistical or demographic data for any
                        purpose. Aggregated Data may be derived from your personal data but is not considered personal
                        data in law as this data does not directly or indirectly reveal your identity. For example, we
                        may aggregate your Usage Data to calculate the percentage of users accessing a specific website
                        feature. However, if we combine or connect Aggregated Data with your personal data so that it
                        can directly or indirectly identify you, we treat the combined data as personal data which will
                        be used in accordance with this privacy notice.
                    </p>

                    <p>
                        We do not collect any Special Categories of Personal Data about you (this includes details about
                        your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
                        political opinions, trade union membership, information about your health and genetic and
                        biometric data). Nor do we collect any information about criminal convictions and offences.
                    </p>

                    <h2>3. How is your personal data collected?</h2>

                    <p>We use different methods to collect data from and about you including through:</p>

                    <ul>
                        <li>
                            Direct interactions. You may give us your Identity, and Contact Data by filling in forms or
                            by corresponding with us by phone, email or otherwise. This includes personal data you
                            provide when you:
                        </li>
                        <li>Complete our enquiry form on our website;</li>
                        <li>
                            Contact us directly by telephone or email or via LinkedIn to seek our help; and when you
                            instruct us to provide services for you by telephone or email and you become our client or
                            candidate.
                        </li>
                        <li>
                            Third parties or publicly available sources. We may receive personal technical data about
                            you from various analytics providers such as Google based outside the EU.
                        </li>
                        <li>
                            Technical Data from the following parties: analytics providers such as Google based outside
                            the EU.
                        </li>
                    </ul>
                </Typography>
            </Container>
        </Band>
        <Band size="md">
            <Container>
                <Contact heading="Let's connect" />
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query PrivacyPageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-N2u-VbYMnmU.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-N2u-VbYMnmU.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
