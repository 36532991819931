import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './Social.scss';

const Social = () => {
    return (
        <ul className="social">
            <li className="social__item">
                <OutboundLink
                    href="https://www.linkedin.com/in/wayne-euston-moore-961a481/"
                    target="_blank"
                    className="social__icon social__icon--linkedin"
                    rel="noopener noreferrer"
                    aria-label="Follow Black Dot Consulting on Linkedin"
                >
                    <FaLinkedinIn />
                </OutboundLink>
            </li>
        </ul>
    );
};

export default Social;
