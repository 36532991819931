import React, { useState } from 'react';
import classNames from 'classnames';

import FormLabel from './FormLabel';
import './Input.scss';

export default ({ label, id, ...rest }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputText, setInputText] = useState('');
    const needsActiveClass = isFocused || inputText !== '';

    return (
        <div
            className={classNames({
                input: true,
                'input--placeholder': true,
                'input--active': !!needsActiveClass,
            })}
        >
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <input
                className="input__input"
                id={id}
                {...rest}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={(e) => setInputText(e.target.value)}
            />
        </div>
    );
};
